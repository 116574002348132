import { useLayoutEffect } from 'react'
/**
 * @description - страница входа в ERP
 * @constructor
 */
const Login = () => {
	useLayoutEffect(() => {
		window.open(`${process.env.REACT_APP_SSO_CLIENT}/?return=${window.location.origin}`, '_self')
	}, [])
	return null
}

export default Login
